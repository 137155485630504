import Joi from 'joi';

const ChangePasswordFormSchema = Joi.object({
  oldPassword: Joi.string().min(8).max(30).pattern(new RegExp('^(?=.*[!@#$%^&*])')).messages({
    'string.empty': 'Password is required',
    'string.min': 'Password must be at least 8 characters',
    'string.max': 'Password must be at most 30 characters',
    'string.pattern.base': 'Password must include one of the following characters: ! @ # $ % ^ & *.',
  }),
  password: Joi.string().min(8).max(30).pattern(new RegExp('^(?=.*[!@#$%^&*])')).messages({
    'string.empty': 'Password is required',
    'string.min': 'Password must be at least 8 characters',
    'string.max': 'Password must be at most 30 characters',
    'string.pattern.base': 'Password must include one of the following characters: ! @ # $ % ^ & *.',
  }),
  confirmPassword: Joi.string()
    .valid(Joi.ref('password'))
    .required()
    .messages({
      'any.only': 'Passwords do not match',
      'string.empty': 'Password confirmation is required',
    }),
});

export default ChangePasswordFormSchema;
