import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const CircularProgressComponent = () => {
    return <Box style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: 'white',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
      }}>
        <CircularProgress />
    </Box>
}

export default CircularProgressComponent