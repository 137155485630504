import { useContext } from 'react';
import Box from '@mui/material/Box';
import AppContext from '../../contexts/app-context';
import classes from './AnalysisPage.module.css';
import StatePieChart from '../../components/Charts/StatePieChart';
import DoctorExpenditureBarChart from '../../components/Charts/DoctorExpenditureBarChart';
import MostUsedDrugsPieChart from '../../components/Charts/MostUsedDrugsPieChart';

const AnalysisPage = () => {
  const appCtx = useContext(AppContext);
  const userRole = appCtx.userRole;

  return (
    <Box>
      <Box className={classes.container}>
        <Box className={classes.pie_box}>
          {userRole === 'insurance_admin' && (
            <>
              <Box className={classes.pie_chart}>
                <MostUsedDrugsPieChart />
              </Box>
            </>
          )}

        <Box className={classes.pie_chart}>
            <StatePieChart />
          </Box>
        </Box>

        {userRole === 'insurance_admin' && (
          <Box className={classes.bar_box}>
            <Box className={classes.bar_chart}>
              <DoctorExpenditureBarChart />
            </Box>
          </Box>
        )}
      </Box>

    </Box>
  );
};

export default AnalysisPage;
