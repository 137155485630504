import { Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../contexts/auth-context";
import { app_paths } from "../config/variables";

const RequireAuth = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;


  return (
    isLoggedIn ? 
    <Outlet /> :
    <Navigate to={app_paths.LOGIN_AUTH} replace={true}/>
  )
}

export default RequireAuth