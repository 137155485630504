import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import useAxios from '../../hooks/useAxios';
import AuthContext from '../../contexts/auth-context';
import AppContext from "../../contexts/app-context";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import AlertTitle from '@mui/material/AlertTitle';
import NewEncounterPageStyle from "./NewEncounterPage.module";
import NewEncounterForm from "../../components/users/NewEncounterForm/NewEncounterForm";
import { urls, toastify_status } from '../../components/config/variables'
import UserProfileNavigation from "../../components/users/UserProfileNavigation/UserProfileNavigation";
import { validateUserResponse } from "../../Utils/validateHttpResponse";
import Toastify from "../../components/ui/Toastify";

const NewEncounterPage = () => {
  const classes = NewEncounterPageStyle();
  const navigate = useNavigate();
  const { operation } = useAxios();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const appCtx = useContext(AppContext);

  const [patientError, setPatientError] = useState(undefined);

  // Javascript is case sensitive, so patientId <> patientID
  const { patientID } = useParams();

  const setUserOnRefresh = (user) => {
    appCtx.addSelectedUser(user);
  }
  /* to be called from the useEffect only if the page refreshes */
  const getPatientFromPatientID = () => {
    operation(
      {
        method: 'GET',
        url: urls.GET_A_PATIENT + patientID,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      handleSetUserOnRefresh,
      setPatientError
    );
  }

  const handleSetUserOnRefresh = (user) => {
    if(validateUserResponse(user)) {
      setUserOnRefresh(user)
    } else {
      console.log('invalid user response')
      Toastify({message: 'invalid user response', state: toastify_status.ERROR})
    }
  }

  useEffect(() => {
    if (typeof appCtx.selectedUser.user === 'undefined') {
      if(patientID && !isNaN(patientID)){
        getPatientFromPatientID()
      } else {
        navigate('/', { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(appCtx.selectedUser)]);

  return (
  <>
  {  patientError ?
    <Alert severity="error" sx={classes.alert_box}>
      <AlertTitle  sx={classes.alert_title}>Patient Not Found</AlertTitle>
      Sorry, no patient was found with the ID {patientID}.<br />
    </Alert>
  :
    <Box sx={classes.container}>
      <UserProfileNavigation />
      <NewEncounterForm />
    </Box>
    }
    </>
  )
}

export default NewEncounterPage;
