import Joi from 'joi';

//check if the primary and secondary insurance have the same value, then return an error message.
const customValidationInsurance = (value, helpers) => {
  const {message, prefs} = helpers
  if(value === prefs.context.otherValue && value !== 1) {
    return message({
      custom: 'Primary and Secondary Insurances cannot have the same value.',
    })
  }
  
  return value;
}

const NewPatientSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .min(5)
    .max(100)
    .messages({
      'string.empty': 'Email is required',
      'string.email': 'Invalid email format',
      'string.min': 'Email must be at least 5 characters',
      'string.max': 'Email must be at most 100 characters',
    }),
  password: Joi.string().min(8).max(30).pattern(new RegExp('^(?=.*[!@#$%^&*])')).messages({
    'string.empty': 'Password is required',
    'string.min': 'Password must be at least 8 characters',
    'string.max': 'Password must be at most 30 characters',
    'string.pattern.base': 'Password must include one of the following characters: ! @ # $ % ^ & *.',
  }),
  name: Joi.string().min(3).max(50).messages({
    'string.empty': 'Name is required',
    'string.min': 'Name must be at least 3 characters',
    'string.max': 'Name must be at most 50 characters',
  }),
  birthdate: Joi.date().iso().max(new Date()).messages({
    'any.required': 'Birthdate is required.',
    'date.base': 'Birthdate must be a valid date.',
    'date.format': 'Birthdate must be in the format "YYYY-MM-DD".',
    'date.max': 'Birthdate must not be greater than the current date.',
  }),
  gender: Joi.string().valid('M', 'F').messages({
    'any.required': 'Gender is required.',
    'any.only': 'Please select a valid Gender (Male or Female).',
  }),
  primary_insurance: Joi.custom(customValidationInsurance).messages({
    'any.only': 'Please select a valid Insurance.',
  }),
  patient_primary_insurance_id: Joi.string().min(5).max(20).messages({
    'string.min': 'Insurance ID  must be at least 5 characters.',
    'string.max': 'Insurance ID must be at most 20 characters',
    'any.required': 'Insurance ID is required.',
    'string.empty': 'Insurance ID is required.',
  }),
  patient_primary_insurance_expiration: Joi.date().iso().min(new Date()).messages({
    'any.required': 'Primary Insurance Expiration is required.',
    'date.base': 'Primary Insurance Expiration must be a valid date.',
    'date.format': 'Primary Insurance Expiration must be in the format "YYYY-MM-DD".',
    'date.max': 'Primary Insurance Expiration must be greater than the current date.',
  }),
  patient_secondary_insurance_expiration: Joi.date().iso().min(new Date()).messages({
    'any.required': 'Secondary Insurance Expiration is required.',
    'date.base': 'Secondary Insurance Expiration must be a valid date.',
    'date.format': 'Secondary Insurance Expiration must be in the format "YYYY-MM-DD".',
    'date.max': 'Secondary Insurance Expiration must be greater than the current date.',
  }),
  
  secondary_insurance: Joi.custom(customValidationInsurance).messages({
    'any.only': 'Please select a valid Insurance.',
  }),
  patient_secondary_insurance_id: Joi.string().min(5).max(20).messages({
    'string.min': 'Insurance ID  must be at least 5 characters.',
    'string.max': 'Insurance ID must be at most 20 characters',
    'any.required': 'Insurance ID is required.',
    'string.empty': 'Insurance ID is required.',
  }),
  phone_number: Joi.string()
    .pattern(/^\+[0-9]+$/)
    .min(7)
    .max(20)
    .allow('')
    .messages({
      'string.pattern.base': 'Phone must start with "+" and contain only digits.',
      'string.min': 'Phone must be at least 7 characters.',
      'string.max': 'Phone must be at most 20 characters',
    }),
  eid: Joi.string().min(7).max(20).allow('').messages({
    'string.min': 'Government ID must be at least 7 characters.',
    'string.max': 'Government ID must be at most 20 characters',
  }),
  physical_address: Joi.string().min(7).max(50).allow('').messages({
    'string.min': 'Physical Address must be at least 7 characters.',
    'string.max': 'Physical Address must be at most 50 characters',
  }),
  employer: Joi.string().min(4).max(50).allow('').messages({
    'string.min': 'Employer must be at least 4 characters.',
    'string.max': 'Employer must be at most 50 characters.',
  }),
  nationality: Joi.string().messages({
    'string.empty': 'Nationality is required.',
    'any.required': 'Nationality is required.',
  }),
});
// nationality: Joi.string().allow('').optional().min(7).max(50).messages({

export default NewPatientSchema;
