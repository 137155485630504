import { createTheme, alpha } from "@mui/material";

export const theme = createTheme({
    typography: {
        fontFamily:'Clash Grotesk, sans-serif',
      },
    palette: {
        primary: {
            light: '#35BCBB1A',
            main: '#4ABBA7',
            dark: '#319190',
            10: '#35BCBB1A',
            20: '#4ABBA733',
            30: '#4ABBA74D',
            contrastText: '#fff',
        },
        custom: {
            black: {
                10: alpha('#000000', 0.1),
                20: alpha('#000000', 0.2),
                30: alpha('#000000', 0.3),
                40: alpha('#000000', 0.4),
                50: alpha('#000000', 0.5),
                60: alpha('#000000', 0.6),
                70: alpha('#000000', 0.7),
                80: alpha('#000000', 0.8),
                90: alpha('#000000', 0.9),
            },
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '5px',
                    fontWeight: '600',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    width: '100%',
                },
                contained: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
    },
});
