
import React, { useEffect, useRef } from 'react';
import axios from "axios";


const AxiosContext = React.createContext(null);

export const AxiosInstanceProvider = ({
  config = {},
  requestInterceptors = [],
  responseInterceptors = [],
  children,
}) => {
  const instanceRef = useRef(axios.create(config));

  useEffect(() => {
    requestInterceptors.forEach((interceptor) => {
      instanceRef.current.interceptors.request.use(
        interceptor
      );
    });
    responseInterceptors.forEach((interceptor) => {
      instanceRef.current.interceptors.response.use(
        interceptor
      );
    });
//});                                           // <--------------- This works fine 
//}, []);                                       // <--------------- This gives warning
}, [requestInterceptors,responseInterceptors]); // <--------------- Is this really needed ???? (Testing works)

  return (
    <AxiosContext.Provider value={instanceRef.current}>
      {children}
    </AxiosContext.Provider>
  );
};

export default AxiosContext;
