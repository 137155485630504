import { Typography, Container, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import  classes  from "./NotFound.module";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Container style={classes.root}>
      {/* <img style={classes.image} src="/images/404-error.svg" alt="404 Error" /> */}
      <div>
        <Typography style={classes.message} variant="h5">
          Loading or this page doesn't exist.
        </Typography>
        <Typography variant="body1">
          If not redirected in few seconds please go to main page
        </Typography>
        <Button
          variant="contained"
          sx={classes.navigate_button}
          onClick={() => navigate("/")}
        >
          Go To The Main Page
        </Button>
      </div>
    </Container>
  );
};

export default NotFound;
