const ChartsStyle = () => {
  return {
    barTitle: {
      fontSize: '20px',
      position: 'absolute',
      top: '-10px',
      left: '50%',
      transform: 'translateX(-50%)',
      letterSpacing: '2px',
    },
    pieTitle: {
      letterSpacing: '2px',
      textAlign: 'center',
      marginTop: '20px',
      marginLeft: '-120px',
      fontSize: '20px'
    },
    notFoundMessageContainer: {
      minHeight: '300px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    notFoundMessageTitle: {
      fontSize: '30px'
    },
    circularProgress: {
      display: 'flex',
      justifyContent: 'center'
    }
  };
};

export default ChartsStyle;
