import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import {useTranslation} from 'react-i18next'

//this search input used inside user profile pages to search by date or search-query 
const SearchInput = ({
  type,
  value,
  name,
  handleChangeSearch,
  dateValidateError,
  label,
  searchBy,
  handleSearch,
  handleClearSearch,
  handleClearDate
}) => {
  const {t} = useTranslation('doctorProfilePage')

  const handleClear = () => {
    if (type === 'date') {
      handleClearDate();
    } else {
      handleClearSearch();
    }
  };

  return (
    <TextField
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
      type={type}
      value={value}
      name={name}
      onChange={handleChangeSearch}
      error={dateValidateError}
      helperText={dateValidateError && 'Choose a valid date to search'}
      fullWidth
      size="small"
      label={label}
      placeholder={
        searchBy === 'icd_code'
        ? t("Search.EnterICDCode")
        : searchBy === 'cpt_code'
        ? t("Search.EnterCPTCode")
        : searchBy === 'drug_name'
        ? t("Search.EnterDrugName")
        : searchBy === 'provider_name'
        ? t("Search.EnterProviderName")
        : searchBy === 'patient_name'
        ? t("Search.EnterPatientName")
        : searchBy === 'state'
        ? t("Search.EnterState")
        : t("Search.SelectSearchBy")
      }
      disabled={searchBy === ''}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {value && (
              <IconButton onClick={handleClear}>
                <ClearIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
