import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// english namespaces
import auth_en from './english/auth.json'
import doctorProfilePage_en from './english/doctorProfilePage.json'
import mainNavigation_en from './english/mainNavigation.json'
import global_en from './english/global.json'
import encounterCard_en from './english/encounterCard.json'
import newPatient_en from './english/newPatient.json'
import newEncounter_en from './english/newEncounter.json'

// arabic namespaces
import auth_ar from './arabic/auth.json'
import doctorProfilePage_ar from './arabic/doctorProfilePage.json'
import mainNavigation_ar from './arabic/mainNavigation.json'
import global_ar from './arabic/global.json'
import encounterCard_ar from './arabic/encounterCard.json'
import newPatient_ar from './arabic/newPatient.json'
import newEncounter_ar from './arabic/newEncounter.json'

const resources = {
  en: {
    auth: auth_en,
    doctorProfilePage: doctorProfilePage_en,
    mainNavigation: mainNavigation_en,
    global: global_en,
    encounterCard: encounterCard_en,
    newPatient: newPatient_en,
    newEncounter: newEncounter_en
  },
  ar: {
    auth: auth_ar,
    doctorProfilePage: doctorProfilePage_ar,
    mainNavigation: mainNavigation_ar,
    global: global_ar,
    encounterCard: encounterCard_ar,
    newPatient: newPatient_ar,
    newEncounter: newEncounter_ar
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    ns: ['auth', 'doctorProfilePage', 'mainNavigation', 'global', 'encounterCard', 'newPatient', 'newEncounter'],
    lng: "en", 
    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;