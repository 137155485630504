import { useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import AuthContext from '../../contexts/auth-context';
import AppContext from '../../contexts/app-context';
import MainNavigationStyle from './MainNavigation.module';
//import { UploadFile } from "@mui/icons-material";
// import {useTranslation} from 'react-i18next'
import { app_paths, appLogoPath } from '../config/variables';
import LanguageSwitcher from '../ui/LanguageSwitcher/LanguageSwitcher';
import Menu from '@mui/material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';

const MainNavigation = ({ toggleDrawer }) => {
  const classes = MainNavigationStyle();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);
  const userRole = appCtx.userRole;
  const loggedInUser = authCtx.userProfile.user

  // const {t} = useTranslation('mainNavigation')

  const logoutHandler = () => {
    authCtx.logout();
    navigate(app_paths.LOGIN_AUTH);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const settingsConfig = [
    {
      icon: <PersonOutlineIcon/>,
      text: 'Profile',
      screen: userRole === 'doctor' ? app_paths.DOCTOR_PROFILE.replace(':doctorName', authCtx.userProfile.user.name) : '/'
    },
    {
      icon: <SettingsIcon/>,
      text: 'Settings',
      screen: app_paths.SETTINGS
    },
    ...(userRole === 'insurance_admin' ? [{
      icon: <StackedBarChartIcon/>,
      text: 'Analytics',
      screen: app_paths.INSURANCE_ANALYSIS
    }] : []),
    {
      icon: <PowerSettingsNewIcon/>,
      text: 'Logout',
      exit: true
    }
  ]

  const SettingComponent = () => {
    return settingsConfig.map((config, i) => {
      return (
        <Box
          key={i}
          sx={classes.settingComponent}
          onClick={() => handleClickSetting(config)}
        >
          {config.icon}
          <Typography sx={classes.settingComponentText}>
            {config.text}
          </Typography>
        </Box>
      );
    });
  };

  const handleClickSetting = (config) => {
    if (config.exit) {
      logoutHandler()
    } else if (config.screen) {
      navigate(config.screen)
    } else {
      return null
    }
    setAnchorEl(null)
  }

  const NavigationMenu = () => {
    return <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <Box sx={classes.navigationMenu}>
        <Box sx={classes.headerNavigationMenu}>
          <Box sx={classes.menuAvatar}>
            <NavLink onClick={() => setAnchorEl(null)} to={userRole === 'doctor' ? app_paths.DOCTOR_PROFILE.replace(':doctorName', authCtx.userProfile.user.name) : '/'}>
              <Avatar
                sx={classes.avatar}
                alt="user1"
                src={authCtx.userProfile.user.gender === "F" ? "/images/doctor_woman.svg" : "/images/doctor_man.svg"}
              />
            </NavLink>
          </Box>
          <Box>
            <Typography sx={classes.headerText}>{loggedInUser.name}</Typography>
            <Typography>{loggedInUser.email}</Typography>
          </Box>
        </Box>
        <Box>
          <SettingComponent />
        </Box>
      </Box>
    </Menu>
  }


  const getInsuranceProfilePage = () => {
    return (
      <Box sx={classes.header}>
        <Typography component="h1" sx={classes.title}>
           {authCtx.userProfile.user.name}
        </Typography>
        <Box sx={classes.profile_logout}>
        <LanguageSwitcher />
        <Avatar
            sx={classes.profile_avatar}
            alt="user1"
            src={authCtx.userProfile.user.gender === "F" ? "/images/doctor_woman.svg" : "/images/doctor_man.svg"}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          />

          <NavigationMenu />

        </Box>
      </Box>
    );
  };

  const getFacilityProfilePage = () => {
    return (
      <Box sx={classes.header}>
        <Typography component="h1" sx={classes.title}>
           {authCtx.userProfile.user.name}
        </Typography>
        <Box sx={classes.profile_logout}>
        <LanguageSwitcher />
        <Avatar
            sx={classes.profile_avatar}
            alt="user1"
            src={authCtx.userProfile.user.gender === "F" ? "/images/doctor_woman.svg" : "/images/doctor_man.svg"}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          />

          <NavigationMenu />
        </Box>
      </Box>
    );
  };

  const getPharmacyProfilePage = () => {
    return (
      <Box sx={classes.header}>
        <Typography component="h1" sx={classes.title}>
          {authCtx.userProfile.user.name}
        </Typography>
        <Box sx={classes.profile_logout}>
          <LanguageSwitcher />

          <Avatar
            sx={classes.profile_avatar}
            alt="user1"
            src={authCtx.userProfile.user.gender === "F" ? "/images/doctor_woman.svg" : "/images/doctor_man.svg"}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          />

          <NavigationMenu />
        </Box>
      </Box>
    );
  };


  const getDoctorProfilePage = () => {
    return (
      <Box sx={classes.header}>
        <Typography component="h1" sx={classes.title}>
          Dr. {authCtx.userProfile.user.name}
        </Typography>
        <Box sx={classes.profile_logout}>
        <LanguageSwitcher />
            <Avatar
              sx={classes.profile_avatar}
              alt="user1"
              src={authCtx.userProfile.user.gender === "F" ? "/images/doctor_woman.svg" : "/images/doctor_man.svg"}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            />

            <NavigationMenu/>
        </Box>
      </Box>
    );
  };

  const getPatientProfilePage = () => {
    return (
      <Box sx={[classes.header, {padding: '0 75px'}]}>
        <Typography component="h1" sx={[classes.title, {padding: '8px 14px', borderRadius: '5px', fontSize: '17px', color: '#fff',backgroundColor: '#4ABBA7'}]}>
          {authCtx.userProfile.user.name}
        </Typography>
        <Box sx={classes.profile_logout}>
        <LanguageSwitcher />
            <Avatar
              sx={classes.profile_avatar}
              alt="user1"
              src={authCtx.userProfile.user.gender === "F" ? "/images/doctor_woman.svg" : "/images/doctor_man.svg"}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            />

            <NavigationMenu/>
        </Box>
      </Box>
    );
  };


  return (
    <Box sx={classes.header_container}>
      {/* {userRole === 'administrator' ? getAdminProfilePage(): null} */}
      {userRole === 'insurance_admin' ? getInsuranceProfilePage(): null}
      {userRole === 'facility_admin' ? getFacilityProfilePage(): null}
      {userRole === 'pharmacy_admin' ? getPharmacyProfilePage(): null}
      {userRole === 'doctor' ? getDoctorProfilePage(): null}
      {userRole === 'patient' ? getPatientProfilePage(): null}
      <Box sx={classes.res_nav_container}>
        <IconButton aria-label="side-panel" onClick={toggleDrawer(true)}>
          <MenuIcon fontSize="large" color="primary" />
        </IconButton>
        <img src={appLogoPath} alt="logo" style={classes.logo} />
      </Box>
      <Divider variant="fullWidth" sx={classes.divider} />
    </Box>
  );
};

export default MainNavigation;
