import { useTheme } from '@emotion/react';

const MainPageStyle = () => {
  const theme = useTheme();

  return{
    container:{ 
     display: 'flex',
     flexDirection: 'row',
     gap: '16px',
     padding: '30px 16px',
     border: '1px dashed',
     borderColor: theme.palette.custom.black['30'],
     borderRadius: '10px'
    },
    text_style: {
      color: theme.palette.custom.black['50']
    }
  
  };

};

export default MainPageStyle;