import { useTheme } from '@emotion/react';

const NewEncounterFormStyle = () => {
  const theme = useTheme();

  return {
    form_container: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '32px',
    },
    inputs_container: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '24px',
    },
    select_inputs_container: {
      display: 'flex',
      flexDirection: { xs: 'column', lg: 'row' },
      gap: '8px',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    select_input: {
      flex: 1.8,
    },
    search_input: {
      width: { xs: '100%', lg: '35%' }
    },
    input_label_container: {
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'space-between'
    },
    input_label: {
      fontSize: '16px',
      fontWeight: '400',
      // color: '#000',
      textTransform: 'capitalize',
      marginBottom: '8px',
      // color: 'red'


    },
    label_and_remove_input_box: {width: '20%', display: 'flex'},
    input_label_container_buttons_box: {
      marginBottom: '10px',
      width: '80%',
      display: 'flex',
      justifyContent: 'flex-end'

    },
    input_label_container_button_circular_progress : {
      position: 'absolute',
      top: '25%',
      left: '50%',
    },
    remove_input_icon_button: {
      color: 'red',
    },
    remove_icon: {
      fontSize: '32px',
      color: 'red',
    },
    search_button: {
      backgroundColor: theme.palette.primary.main,
      padding: '6px',
      color: '#fff',
      '&: hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    clear_button: {
      marginLeft: '-10px',
    },
    menu_props: {
      maxHeight: 280,
    },
    add_more: {
      backgroundColor: '#565656',
      fontWeight: '600',
      fontSize: '16px',
      padding: '10px 0',
      '&: hover': {
        backgroundColor: '#454545',
      },
    },
    add_treatment: {
      color: '#000',
      fontWeight: '400',
      fontSize: '18px',
      position: 'sticky',
      bottom: 0,
      textAlign: 'center',
      backgroundColor: '#fff',
      zIndex: '1',
      '&: hover': {
        backgroundColor: '#fff',
      },
    },
    treatment_inputs_container: {
      display: 'flex',
      rowGap: '24px',
      columnGap: '16px',
      flexDirection: 'row',
      flexWrap: 'wrap',

    },
    drug_name_box: { 
      flexBasis: '99%',
      marginTop: '8px',
    },
    treatment_input_control: {
      flexBasis: { xs: '100%', md: '48%', xl: '49%' },

    },

    form_actions: {
      display: 'flex',
      flexDirection: 'row',
      gap: '12px',
      marginTop: '32px',

    },
    submit: {
      fontSize: '16px',
      fontWeight: '600',
      backgroundColor: theme.palette.primary.main,
      padding: '10px 70px',
      maxWidth: '200px',
    },
    clear: {
      fontSize: '16px',
      fontWeight: '600',
      backgroundColor: '#565656',
      padding: '10px 70px',
      maxWidth: '200px',
      '&: hover': {
        backgroundColor: '#454545',
      },
    },
    found_options: {
      color: theme.palette.custom.black['80'],
      fontWeight: '500',
    },
    not_found_options: {
      color: 'red',
    },
    patient_info_box: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    patient_info_text: {
      flexBasis: { xs: '100%', lg: '100%' },
      padding: { xs: '4px 8px', sm: '12px 8px' },
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      textAlign: 'left',
    },
    patient_insu_select: {
      flexBasis: { xs: '100%', lg: '100%' },
      maxHeight: '50px',
      // //padding: { xs: '4px 8px', sm: '12px 8px' },
      // border: '1px solid #d9d9d9',
      borderRadius: '10px',
      textAlign: 'left',
      backgroundColor: 'lightgrey'
    },
    patient_insu_menu: {
      // flexBasis: { xs: '50%', lg: '50%' },
      // maxHeight: '1px',
      // //padding: { xs: '4px 8px', sm: '12px 8px' },
      // border: '1px solid #d9d9d9',
      // borderRadius: '100px',
      textAlign: 'left',
    },
    circular: {
      marginLeft: '50%',
      transform: 'translate(-50%, -50%)',
    },
    dialog_title: {
      fontSize: '24px',
      textAlign: 'center'
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    alternative_box: {
      marginBottom: '10px'
    },
    alternative_typography: {
      cursor: 'pointer',
      display: 'inline-block',
      padding: '5px',
      transition: '0.2s',
      color: 'inherit',
      '&:hover': {
        color: '#204969',
      }
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '30px'
    },
    open_dialog_button: {
      marginLeft: '10px',
      position: 'relative',
      width: '170px',
      padding: '0 10px',
      fontSize: '14px',
      border: '1px solid #C4C4C4',
      '&: hover': {
      backgroundColor: '#4ABBA7',
      color: '#fff'
  }
    },
    dialog_button_title:{
      fontWeight: '500',
    },
    alternative_table_row: {
      transition: '0.5s',
      cursor: 'pointer',
      '&: hover': {
          backgroundColor: '#F5F7F8'
      }
    },
    alternative_drugs_circular: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%'
    },
    header_table_cell: {
      textAlign: 'center',
      padding: '4px',
      fontSize: '18px',
      color: '000000c7'
    },
    table_cell: {
      textAlign: 'center',
      padding: '14px'
    },
    dialogContent: {
      height: '600px',
      position: 'relative'
    },
    star_box: {
      borderRadius: '4px',
      marginLeft: '10px',
      border: '1px solid #C4C4C4',
      height: '40px',
      width: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchable_and_star_box_container: {
      width: { xs: '100%', lg: '65%' },
      display: 'flex',
    }
  };
};

export default NewEncounterFormStyle;
