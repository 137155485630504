import { useState, useEffect, useContext } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import AuthContext from '../../contexts/auth-context';
import AppContext from '../../contexts/app-context';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SideBarStyle from './SideBar.module';
import useAxios from '../../hooks/useAxios';
import { urls, PAGE_SIZE, app_paths, appLogoPath, company, patientFormType } from '../config/variables';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {useTranslation} from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress';

const PatientCard = ({ patient }) => {
  const classes = SideBarStyle();
  const appCtx = useContext(AppContext);
  const userRole = appCtx.userRole;
  const handleSelectUser = () => {
    appCtx.addSelectedUser(patient);
  };
  var navigationpath = app_paths.PATIENT_PROFILE.replace(':patientID', patient.id);
  if (['doctor', 'pharmacy_admin'].includes(userRole)){
    navigationpath = app_paths.NEW_ENCOUNTER.replace(':patientID', patient.id);;
  }
  return (
    <NavLink onClick={handleSelectUser} to={`${navigationpath}`} style={classes.link}>
      <Box sx={classes.patient_card}>
        <Avatar alt={patient.user.name} src={patient.user.gender === 'F' ? '/images/patient_woman.svg' : '/images/patient_man.svg'} />
        <Typography sx={classes.patient_card_username}>{patient.user.name}</Typography>
      </Box>
    </NavLink>
  );
};

const SideBar = ({ toggleDrawer }) => {
  const classes = SideBarStyle();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);
  const { operation } = useAxios();

  const [searchQuery, setSearchQuery] = useState('');
  const [usersList, setUsersList] = useState();
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const [usersListError, setUsersListError] = useState();

  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const {t} = useTranslation('mainNavigation')

  const reformateData = (sliceIdx) => {
    return sliceIdx.map((patient) => {
      return <PatientCard key={patient.id} patient={patient} />;
    });
  };

  const setFilteredUserList = (users) => {
    setLoadingUsersList(false)
    setUsersList(reformateData(users.results))
    setCount(Math.ceil(users.count / PAGE_SIZE));
    appCtx.addUsersList(users);
  }

  const getUsersList = (filter) => {
    setLoadingUsersList(true)
    operation(
      {
        method: 'GET',
        url: urls.LIST_PATIENTS + filter,
        headers: { Authorization: `token ${authCtx.token}` },
        data: {},
      },
      setFilteredUserList,
      handleUsersListError
    );
  }

  const handleUsersListError = (error) => {
    setLoadingUsersList(false)
    setUsersListError(error)
  }


  useEffect(() => {
    if(!appCtx.reFetchUsersList) {
      const searchForPatient = `?searchfor=${searchQuery}`

      const filter = searchQuery
       ? `${searchForPatient}&page=${page}&page_size=${PAGE_SIZE}`
       :`?page=${page}&page_size=${PAGE_SIZE}`

      getUsersList(filter)
    }
    // eslint-disable-next-line
  }, [page]);

  

  const handleSearchClick = (event) => {
    event.preventDefault();
    setPage(1)
    getUsersList(`?searchfor=${searchQuery}&page=1&page_size=${PAGE_SIZE}`)
  };


  const handleSearch = (event) => {
      setSearchQuery(event.target.value)
  };

  const handleClearSearch = () => {
    setSearchQuery('')
    setPage(1);
    getUsersList(`?page=1&page_size=${PAGE_SIZE}`)
  }


  const logoutHandler = () => {
    authCtx.logout();
    navigate(app_paths.LOGIN_AUTH);
  };

  useEffect(() => {
    if(count === 0 && !searchQuery){
      handleClearSearch()
    }
  // eslint-disable-next-line
  }, [count, searchQuery])

  // this is 2 cases of reload users list
  useEffect(() => {
    if(appCtx.reFetchUsersList) {
      if(appCtx.reFetchUsersList === patientFormType.create) {
        // if form type is create then fetch the first page of the users
        getUsersList(`?page=1&page_size=${PAGE_SIZE}`)
        setPage(1);
      } else {
        // if form type is update then fetch the current page of the users
        getUsersList(`?page=${page}&page_size=${PAGE_SIZE}`)
      }
    }
    // eslint-disable-next-line
  }, [appCtx.reFetchUsersList])

  return (
    <Box sx={classes.container}>
      <Box sx={classes.side_controls}>
        <NavLink to="/">
          <img src={appLogoPath} alt="logo" style={classes[company]} />
        </NavLink>
        <IconButton aria-label="side-panel" onClick={toggleDrawer(false)} sx={classes.close_button}>
          <ClearIcon fontSize="large" color="primary" />
        </IconButton>
      </Box>
      <Divider variant="fullWidth" />
      <Box sx={classes.side_bar_contents}>
        <TextField
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: searchQuery && (
              <InputAdornment position="start">
                <IconButton onClick={handleClearSearch}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearchClick} sx={classes.search_button} disabled={!searchQuery}>
                 <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Enter Patient Name"
          sx={classes.search}
          size="small"
        />
        <Typography component="h3" sx={classes.side_bar_title}>
          {/* Patients List */}
        </Typography>
      </Box>
      {
  usersListError ? (
    <Alert severity="error">
        <AlertTitle>User List Not Found</AlertTitle>
    </Alert>
  ) : (
    <>
    {loadingUsersList ? <Box sx={classes.usersListCircularProgress}>
                <CircularProgress />
              </Box> : <>
                {count > 0 && (
                  <Box sx={classes.patients_container}>
                    {usersList}
                    <Box sx={classes.pagination}>
                      <Stack spacing={2} sx={classes.stack}>
                        <Pagination
                          page={page}
                          disabled={count === 1}
                          count={count}
                          variant="outlined"
                          color="primary"
                          size="medium"
                          onChange={(e, v) => setPage(v)}
                        />
                      </Stack>
                    </Box>
                  </Box>
                )}
              </>}

      <Box sx={classes.profile_logout}>
        <Divider variant="fullWidth" sx={classes.divider} />

        <Button variant="contained" onClick={logoutHandler} sx={classes.logout_button}>
          {t('logout')}
        </Button>

        <NavLink to={app_paths.DOCTOR_PROFILE.replace(':doctorName', authCtx.userProfile.user.name)}>
          <Avatar
            sx={classes.profile_avatar}
            alt="user1"
            src={
              authCtx.userProfile.user.gender === 'F'
                ? '/images/doctor_woman.svg'
                : '/images/doctor_man.svg'
            }
          />
        </NavLink>
      </Box>
    </>
  )
}

    </Box>
  );
};

export default SideBar;
