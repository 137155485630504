import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../contexts/app-context';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../components/ui/SearchInput';
import DoctorExpenditureBarChart from '../../components/Charts/DoctorExpenditureBarChart';
import classes from './InsuranceAnalysisPage.module.css';
import FrequencyDoctorExpenditureBarChart from '../../components/Charts/frequencyCharts/DoctorExpenditureBarChart';

const InsuranceAnalysisPage = () => {
  const { t } = useTranslation('doctorProfilePage');
  const appCtx = useContext(AppContext)

  const [searchQuery, setSearchQuery] = useState('');
  const searchBy = appCtx.insuranceChartSearchBy
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [dateValidateError, setDateValidateError] = useState(false);
  const [disabledSearchButton, setDisabledSearchButton] = useState(true);
  const searchByFrequencyDateQuery = appCtx.searchByFrequencyDateQuery

  const SearchByMenuItems = [
    { key: 1, label: t('Search.Date'), value: 'date' },
    { key: 2, label: 'frequency date', value: 'frequency_date' },
  ];

  useEffect(() => {
    const isValidDate = fromDate && toDate && !dateValidateError;
    const isValidSearch = searchQuery && !dateValidateError;

    setDisabledSearchButton(!(isValidDate || isValidSearch));
  }, [fromDate, toDate, searchQuery, dateValidateError]);

  const handleChangeSearchBy = (event) => {
    appCtx.handleSetInsuranceChartSearchBy(event.target.value)
    setDateValidateError(false);
    setSearchQuery('');
    setFromDate('');
    setToDate('');
    appCtx.handleSetSearchByFrequencyDateQuery('daily')
  };

  const handleChangeSearch = (e) => {
    const {value, name} = e.target

    if (searchBy === 'date') {
      const currentDate = new Date();
      const date = new Date(value);

      if (date < currentDate) {
        if(name === 'fromDate'){
          setFromDate(value);
        } else if (name === 'toDate'){
          setToDate(value);
        }
        setDateValidateError(false);
      } else {
        setDateValidateError(true);
      }

    } else {
      setSearchQuery(value);
    }
  };

  const handleSearch = () => {
    // handle search
    if (searchBy === 'date') {
     appCtx.handleSetSearchByDateQuery(`?from_date=${fromDate}&to_date=${toDate}`)
    }
  };

  const handleClearDate = (value) => {
    if(value === 'fromDate'){
      setFromDate('')
    } else if (value === 'toDate'){
      setToDate('')
    }
    setDateValidateError(false);
    appCtx.handleSetSearchByDateQuery('')
  }

  const handleSelectState = (e) => {
    const { name } = e.target;
    appCtx.handleSetSearchByFrequencyDateQuery(name);
  };

  return (
    <div>
      <Box className={classes.container}>

      <Box className={classes.search_container}>
        <FormControl fullWidth className={classes.search_by} size="small">
          <InputLabel>{t('Search.SearchBy')}</InputLabel>
          <Select
            label={t('Search.SearchBy')}
            value={searchBy}
            onChange={handleChangeSearchBy}
          >
            {SearchByMenuItems.map((item) => (
              <MenuItem key={item.key} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {searchBy === 'date' ? (
          <>
            <SearchInput
              handleSearch={handleSearch}
              type="date"
              value={fromDate}
              name="fromDate"
              handleChangeSearch={handleChangeSearch}
              dateValidateError={dateValidateError}
              label={t('Search.FromDate')}
              handleClearDate={() => handleClearDate('fromDate')}
            />
            <SearchInput
              handleSearch={handleSearch}
              type="date"
              value={toDate}
              name="toDate"
              handleChangeSearch={handleChangeSearch}
              dateValidateError={dateValidateError}
              label={t('Search.ToDate')}
              handleClearDate={() => handleClearDate('toDate')}
            />

        <Button
          disabled={disabledSearchButton}
          variant="contained"
          onClick={handleSearch}
          className={classes.search_button}
        >
          {t('Search.Search')}
        </Button>
          </>
        ) : (
          <Box className={classes.state_box}>
            {['daily', 'weekly', 'monthly', 'yearly'].map((period) => (
              <Button
                key={period}
                sx={{ margin: '0 10px' }}
                name={period}
                variant={
                  searchByFrequencyDateQuery === period ? 'contained' : 'outlined'
                }
                color="success"
                onClick={handleSelectState}
              >
                {period}
              </Button>
            ))}
          </Box>
        )}
      </Box>

      <Box className={classes.bar_box}>
          {searchBy === 'date' ? (
            <Box className={classes.bar_chart}>
             <DoctorExpenditureBarChart />
            </Box>
          ) : (
            <Box className={classes.bar_chart}>
             <FrequencyDoctorExpenditureBarChart />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default InsuranceAnalysisPage;
