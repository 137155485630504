import { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MainPageStyle from './MainPage.module.';
import {useTranslation} from 'react-i18next'
import AppContext from '../../contexts/app-context';


function MainPage() {
  const classes = MainPageStyle();
  const appCtx = useContext(AppContext)
  const direction = appCtx.direction
  const {t} = useTranslation('global')

    return (
        <Box sx={classes.container} dir={direction}>
            <img src="/images/mousecircle.svg" alt="select-icon"/>
            <Typography sx={classes.text_style}>{t('SelectPatient')}</Typography>
        </Box>
    );
}

export default MainPage;
