import { useContext } from "react";

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UserProfileNavigationStyle from './UserProfileNavigation.module';
import { NavLink } from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import AppContext from "../../../contexts/app-context";
import {app_paths} from  '../../config/variables'

const UserProfileNavigation = () => {
  const classes = UserProfileNavigationStyle();
  const appCtx = useContext(AppContext);
  const direction = appCtx.direction
  const {t} = useTranslation('doctorProfilePage')
  return (
    <>
    {typeof appCtx.selectedUser.user !== 'undefined' ?
    <Box sx={classes.container}>
    <Box sx={classes.patient_profile}>
      <Avatar
        src={appCtx.selectedUser.user.gender === 'F' ? '/images/patient_woman.svg' : '/images/patient_man.svg'}
        alt="patient-profile"
        sx={classes.patient_profile_img}
      />
      <Box>
        <Typography sx={classes.patient_profile_name}>{appCtx.selectedUser.user.name}</Typography>
        <NavLink to={app_paths.PATIENT_PROFILE.replace(':patientID', appCtx.selectedUser.id)}>
          <Button variant="contained" endIcon={<ArrowForwardIcon />} sx={classes.patient_profile_button}>
            {t("OpenProfile")}
          </Button>
        </NavLink>
      </Box>
    </Box>

    <Box sx={classes.patient_info_container} dir={direction}>
      <Box sx={classes.patient_info_box}>
        <Typography sx={classes.patient_info_text}>
          {t("GovernmentID")}
          <Typography component="span" sx={classes.patient_info_span}>
            {appCtx.selectedUser.user.eid}
          </Typography>
        </Typography>
      </Box>
      <Box sx={classes.patient_info_box}>
        <Typography sx={classes.patient_info_text}>
           {t("DateOfBirth")}
          <Typography component="span" sx={classes.patient_info_span}>
            {appCtx.selectedUser.user.birthdate}
          </Typography>
        </Typography>
      </Box>
      <Box sx={classes.patient_info_box}>
        <Typography sx={classes.patient_info_text}>
          {t("Gender")}
          <Typography component="span" sx={classes.patient_info_span}>
            {appCtx.selectedUser.user.gender === 'F' ? 'Female' : 'Male'}
          </Typography>
        </Typography>
      </Box>
    </Box>
  </Box>
    :
    <p></p>
    }
    </>
  )
  }

export default UserProfileNavigation;
