import { useContext, useState, useEffect, memo } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import NewEncounterFormStyle from './NewEncounterForm.module';
import useAxios from '../../../hooks/useAxios';
import AuthContext from '../../../contexts/auth-context';
import { urls, PAGE_SIZE, MIN_NUMBER_OF_SEARCH, toastify_status } from '../../config/variables';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next'
import Button from '@mui/material/Button';
import MedicalSelections from './MedicalSelections';
import { validateDrugsCode, validateAlternativeDrugs, validateRelatedDrugs } from '../../../Utils/validateHttpResponse';
import Toastify from '../../ui/Toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SearchableSelect from '../../ui/SearchableSelect/SearchableSelect';
import { GlobalStyles } from '../../../constants/GlobalStyles';

const DrugName = memo(({ index, handleSelectDrug, isDisabled, clearSearch, handleDeleteDrugValue, getSelectedOptionObject, setProductId, handleSetSelectedDrug, diagnoses, favourite, updateFavourite }) => {
  const classes = NewEncounterFormStyle();
  const { operation } = useAxios();
  const authCtx = useContext(AuthContext);
  const [product_id, setproduct_id] = useState('');
  const [selectedDrug, setSelectedDrug] = useState('');
  const [drugOptions, setDrugOptions] = useState([]);
  const [loadingDrugOptions, setLoadingDrugOptions] = useState(false);
  const [drugOptionsError, setDrugOptionsError] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState('');
  const [prevSearchQuery, setPrevSearchQuery] = useState('');
  const [selectInputLabel, setSelectInputLabel] = useState('Please search first before selecting.');
  const {t} = useTranslation('newEncounter')
  // alternative drug options states
  const [alternativeDrugOptions, setAlternativeDrugOptions] = useState([])
  const [alternativeDrugOptionsError, setAlternativeDrugOptionsError] = useState(undefined);
  const [openAlternativeDrugsPopup, setOpenAlternativeDrugsPopup] = useState(false);
  // isAlternative to check if the selected drug is alternative or no to take the drug label from the options list (drugOptions or alternativeDrugOptions)
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [loadingAlternativeDrugs, setLoadingAlternativeDrugs] = useState(false);
  const [formattedDiagnoses, setFormattedDiagnoses] = useState([]);
  // related drugs
  const [relatedDrugOptions, setRelatedDrugOptions] = useState([]);
  const [relatedDrugOptionsError, setRelatedDrugOptionsError] = useState(undefined);
  const [openRelatedDrugOptions, setOpenRelatedDrugOptions] = useState(false);
  const [loadingRelatedDrugs, setLoadingRelatedDrugs] = useState(false);
  // favourite drugs
  const [favouriteDrugOptions, setFavouriteDrugOptions] = useState([])
  const [openFavouriteDrugsDialog, setOpenFavouriteDrugsDialog] = useState(false);
  // check if the selected diagnoses is a favourite diagnose or no
  const [selectedFavouriteDrug, setsSelectedFavouriteDrug] = useState(false);
  const [loadingFavourite, setLoadingFavourite] = useState(false);

  useEffect(() => {
    if(Array.isArray(favourite.drugs)) {
      setFavouriteDrugOptions(favourite.drugs)
      setLoadingFavourite(false)
    }
  }, [favourite])

  const handleSetDrugOptions = (resDrugOptions) => {
    setLoadingDrugOptions(false)
    if(validateDrugsCode(resDrugOptions)) {
      setDrugOptions(resDrugOptions);
      setSelectedDrug('')
      handleSetSelectedDrug('')
      resDrugOptions.length > 0
        ? setSelectInputLabel(<Typography sx={classes.found_options}>{`Search or select from (${resDrugOptions.length}) options...`}</Typography>)
        : setSelectInputLabel(<Typography sx={classes.not_found_options}>No Drugs found for "{searchQuery}".</Typography>);
      } else {
        console.log('invalid diagnosis response')
        Toastify({message: 'invalid diagnosis response', state: toastify_status.ERROR})
      }
    };

  const handleSubmit = () => {
    setPage(1)
    setAlternativeDrugOptions([])
    if (searchQuery.length >= MIN_NUMBER_OF_SEARCH) {
      setDrugOptionsError('');
      setLoadingDrugOptions(true)
      operation(
        {
          method: 'POST',
          url: urls.LIST_SEARCHED_DRUGS,
          headers: { Authorization: `token ${authCtx.token}` },
          data: {"searchfor": searchQuery},
        },
        handleSetDrugOptions,
        handleSetDrugOptionsError
      );
    }
  };

  const handleSetDrugOptionsError = (error) => {
    setLoadingDrugOptions(false)
    setDrugOptionsError(error)
  }

  const handleSearch = (event) => {
    const value = event.target.value;
    if (typeof value !== 'undefined') {
      setSearchQuery(value);
    }
  };

  const handleClearDrug = (clearSearchQuery) => {
    if(clearSearchQuery) {
      setSearchQuery('');
    }
      setSelectedDrug('')
      handleSetSelectedDrug('')
      setproduct_id('')
      setDrugOptions([]);
      setAlternativeDrugOptions([])
      handleDeleteDrugValue(index);
      setDrugOptionsError(undefined);
      setSelectInputLabel('Please search first before selecting.');
      getSelectedOptionObject(null);
      setsSelectedFavouriteDrug(false)
  }

  const handleSelectOption = (event, index, selectedOptionsResource) => {
    // selectedOptionsResource is a string pass over from (selectAlternativeDrugOption or selectRelatedDrugOption Functions)
    setproduct_id(event.target.value)
    // set product id is a function comes from TreatmentForm page
    setProductId(event.target.value)
    handleSelectDrug(event, index);
    const options =
    selectedOptionsResource === 'alternative'
        ? alternativeDrugOptions
        : selectedOptionsResource === 'related'
        ? relatedDrugOptions
        : selectedOptionsResource === 'favorite'
        ? favouriteDrugOptions
        : drugOptions;

    const selectedOptionObject = options.find((option) => option.product_id === event.target.value);
    getSelectedOptionObject(selectedOptionObject);
    setSelectedDrug(selectedOptionObject)
    handleSetSelectedDrug(selectedOptionObject)
    switch (selectedOptionsResource) {
      case 'alternative':
        setDrugOptions([{...selectedOptionObject}]);
        setOpenAlternativeDrugsPopup(false);
        break;

      case 'related':
        setDrugOptions([{...selectedOptionObject}]);
        setOpenRelatedDrugOptions(false);
        break;

      case 'favorite':
        setDrugOptions([{...selectedOptionObject}]);
        setOpenFavouriteDrugsDialog(false);
        break;

      default:
        break;
    }
  };
  // select drug by search for Medication Name
  const selectDrug = (drug) => {
    const event = {target: {name: 'drug', value: drug.product_id}}
    handleSelectOption(event, index)
  }

  useEffect(() => {
    handleClearDrug()
    // eslint-disable-next-line
  }, [clearSearch]);

  const handleOpenAlternativeDrugsPopup = () => {
    setOpenAlternativeDrugsPopup(true);
  };

  const handleCloseAlternativeDrugsPopup = () => {
    setOpenAlternativeDrugsPopup(false);
  };

  const selectAlternativeDrugOption = (drug) => {
    const event = {target: {name: 'drug', value: drug.product_id}}
    handleSelectOption(event, index, 'alternative')
    setSearchQuery(drug.product_label_name)
  }

  useEffect(() => {
    if(product_id) {
      fetchAlternativeDrugOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id, page])

  const fetchAlternativeDrugOptions = () => {
    setLoadingAlternativeDrugs(true)
      operation(
        {
          method: 'GET',
          url: `${urls.ALTERNATIVE_DRUG_OPTIONS}${product_id}?page=${page}&page_size=${PAGE_SIZE}`,
          headers: { Authorization: `token ${authCtx.token}` },
          data: {},
        },
        getAlternativeDrugOptions,
        setAlternativeDrugOptionsError
      );
  };

  const getAlternativeDrugOptions = (alternativeOptions) => {
    setLoadingAlternativeDrugs(false)
    if(validateAlternativeDrugs(alternativeOptions)) {
      setAlternativeDrugOptions(alternativeOptions.results)
      setCount(Math.ceil(alternativeOptions.count / 10));
    } else {
      console.log('invalid alternative drugs response')
      Toastify({message: 'invalid alternative drugs response', state: toastify_status.ERROR})
    }
  }

  // formate selected diagnoses to use it to fetch related drugs
  useEffect(() => {
    if (diagnoses && Array.isArray(diagnoses)) {
      setFormattedDiagnoses(diagnoses.filter((diagnose) => diagnose && diagnose.icd).map((diagnose) => diagnose.icd))
    }
  }, [diagnoses])

  const fetchRelatedDrugOptions = () => {
    setLoadingRelatedDrugs(true)
    setRelatedDrugOptionsError(undefined)
      operation(
        {
          method: 'POST',
          url: urls.LIST_SEARCHED_DRUGS,
          headers: { Authorization: `token ${authCtx.token}` },
          data: {"searchfor": '',
            "icd": formattedDiagnoses},
        },
        handleSetRelatedDrugOptions,
        handleSetRelatedDrugOptionsError
      );
  }

  const handleSetRelatedDrugOptions = (relatedDrugs) => {
    setLoadingRelatedDrugs(false)
    if(validateRelatedDrugs(relatedDrugs)) {
      setRelatedDrugOptions(relatedDrugs)
    } else {
      Toastify({message: 'invalid related drugs response', state: toastify_status.ERROR})
    }
  }

  const handleSetRelatedDrugOptionsError = (relatedDrugsError) => {
    setRelatedDrugOptionsError(relatedDrugsError)
    setRelatedDrugOptions([])
    setLoadingRelatedDrugs(false)
  }

  useEffect(() => {
    if(formattedDiagnoses.length > 0) {
      fetchRelatedDrugOptions()
    } else {
      setRelatedDrugOptions([])
    }
    // eslint-disable-next-line
  }, [formattedDiagnoses])

  const handleOpenRelatedDrugsPopup = () => {
    setOpenRelatedDrugOptions(true);
  };

  const handleCloseRelatedDrugsPopup = () => {
    setOpenRelatedDrugOptions(false);
  };

  const selectRelatedDrugOption = (drug) => {
    const event = {target: {name: 'drug', value: drug.product_id}}
    handleSelectOption(event, index, 'related')
    setSearchQuery(drug.product_label_name)
  }

  // Favorite Drugs

  const handleOpenFavouriteDrugsPopup = () => {
    setOpenFavouriteDrugsDialog(true);
  };

  const handleCloseFavouriteDrugsPopup = () => {
    setOpenFavouriteDrugsDialog(false);
  };

  const selectFavouriteDrug = (drug) => {
    const event = {target: {name: 'drug', value: drug.product_id}}
    handleSelectOption(event, index, 'favorite')
    setSearchQuery(drug.product_label_name)
  }

  // check if the selected diagnoses is a favourite diagnose or no
  useEffect(() => {
    if (product_id) {
      setsSelectedFavouriteDrug(favouriteDrugOptions.find((drug) => drug.product_id === product_id));
    }
  }, [product_id, favouriteDrugOptions]);

  const addFavouriteDrug = () => {
    const favouriteDrugList = favouriteDrugOptions.map((drug) => drug.product_id)
    const updatesDrugsList = [...favouriteDrugList, product_id]
    const data = {'drugs': updatesDrugsList}
    updateFavourite(data)
    setLoadingFavourite(true)
  }

  const RemoveFavouriteDrug = () => {
    const updatedDrugList = favouriteDrugOptions.map((drug) => drug.product_id).filter((drug) => drug !== product_id)
    const data = {'drugs': updatedDrugList}
    updateFavourite(data)
    setLoadingFavourite(true)
  }

  useEffect(() => {
    setPrevSearchQuery(searchQuery)
  }, [searchQuery])

  /* use effect to clear Drugs in case of the user removed or changed the search query
     after get Drug options or select Drug */
     useEffect(() => {
      if (
        (product_id &&
         (!searchQuery || (prevSearchQuery.length >= 3 && searchQuery.length === 2))) ||
        (drugOptions.length > 0 && searchQuery.length <= 2)
      ) {
        handleClearDrug();
      }
    // eslint-disable-next-line
    }, [product_id, searchQuery, prevSearchQuery, drugOptions]);

  return (
    <Box sx={classes.container}>
      <Box sx={classes.input_label_container}>
        <InputLabel disabled={isDisabled} sx={classes.input_label}>
          {t("MedicationName")}
        </InputLabel>
        <Box sx={classes.input_label_container_buttons_box}>
            <Button
              sx={classes.open_dialog_button}
              onClick={handleOpenRelatedDrugsPopup}
              disabled={isDisabled || (!loadingRelatedDrugs && relatedDrugOptions.length === 0)}
            >
                <Typography
                 sx={[classes.dialog_button_title, {opacity: loadingRelatedDrugs && GlobalStyles.opacity.half}]}>
                  Related Drugs
                </Typography>
                  {!isDisabled && loadingRelatedDrugs && (
                    <CircularProgress
                      size={14}
                      sx={classes.input_label_container_button_circular_progress}
                    />
                  )}
            </Button>

            <Button
              disabled={isDisabled || (!loadingAlternativeDrugs && alternativeDrugOptions.length === 0)}
              sx={classes.open_dialog_button}
              onClick={handleOpenAlternativeDrugsPopup}>
                <Typography
                 sx={[classes.dialog_button_title, {opacity: loadingAlternativeDrugs && GlobalStyles.opacity.half}]}>
                  {t("Alternative")}
                </Typography>
                {!isDisabled && loadingAlternativeDrugs && (
                    <CircularProgress
                      size={14}
                      sx={classes.input_label_container_button_circular_progress}
                    />
                  )}
            </Button>

            <Button
              sx={classes.open_dialog_button}
              onClick={handleOpenFavouriteDrugsPopup}
              disabled={isDisabled || (!loadingFavourite && favouriteDrugOptions.length === 0)}
            >
              <Typography
               sx={[classes.dialog_button_title, {opacity: loadingFavourite ? GlobalStyles.opacity.half : GlobalStyles.opacity.full}]}>
                Favorite Drugs
              </Typography>
              {!isDisabled && loadingFavourite && (
                <CircularProgress
                  size={14}
                  sx={classes.input_label_container_button_circular_progress}
                />
              )}
            </Button>
          </Box>
      </Box>


      <Box sx={classes.select_inputs_container}>
        <TextField
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          disabled={isDisabled}
          variant="outlined"
          InputProps={{
            startAdornment: searchQuery && (
              <InputAdornment position="start">
                <IconButton onClick={() => handleClearDrug('clearSearchQuery')} sx={classes.clear_button}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSubmit} sx={classes.search_button} disabled={searchQuery.length < MIN_NUMBER_OF_SEARCH}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={searchQuery}
          onChange={handleSearch}
          placeholder={`Search for at least ${MIN_NUMBER_OF_SEARCH} characters`}
          sx={classes.search_input}
          size="small"
          error={!!drugOptionsError}
          helperText={drugOptionsError ? drugOptionsError.message : ''}
        />

        <Box sx={classes.searchable_and_star_box_container}>
          <SearchableSelect
              value={selectedDrug}
              onChange={selectDrug}
              options={loadingDrugOptions ? [] : drugOptions}
              placeholder={selectInputLabel}
              disabled={isDisabled || drugOptions.length === 0 || loadingDrugOptions}
              getOptionLabel={(option) => `${option.product_label_name} - pkg size ${parseInt(option.product_package_size)}`}
              getOptionValue={(option) => option.product_id}
              loading={loadingDrugOptions}
            />

            <Box sx={classes.star_box}>
              {loadingFavourite ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  disabled={!product_id}
                  onClick={selectedFavouriteDrug ? RemoveFavouriteDrug : addFavouriteDrug}
                >
                  <Tooltip title={selectedFavouriteDrug ? 'Remove From Favourites' : 'Add To Favourites'}>
                    {selectedFavouriteDrug ? <StarIcon /> : <StarBorderIcon />}
                  </Tooltip>
                </IconButton>
              )}
          </Box>
        </Box>

      </Box>
      <MedicalSelections
        selectionType='drug'
        title='Alternative Drug'
        open={openAlternativeDrugsPopup}
        handleClose={handleCloseAlternativeDrugsPopup}
        loading={loadingAlternativeDrugs}
        data={alternativeDrugOptions}
        error={alternativeDrugOptionsError}
        selectOption={selectAlternativeDrugOption}
        count={count}
        handlePageChange={(v) => setPage(v)}
        alternative={true}
      />

    <MedicalSelections
        selectionType='drug'
        title='Related Drug'
        open={openRelatedDrugOptions}
        handleClose={handleCloseRelatedDrugsPopup}
        loading={loadingRelatedDrugs}
        data={relatedDrugOptions}
        error={relatedDrugOptionsError}
        selectOption={selectRelatedDrugOption}
      />

      <MedicalSelections
        selectionType='drug'
        title='Favourite Drug'
        open={openFavouriteDrugsDialog}
        handleClose={handleCloseFavouriteDrugsPopup}
        loading={loadingRelatedDrugs}
        data={favouriteDrugOptions}
        selectOption={selectFavouriteDrug}
      />
    </Box>
  );
});

export default DrugName;
