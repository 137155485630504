import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const TableComponent = ({ tableHead, tableBody, bodyCells, customStyle, clickHandler }) => {
    const tableHeadCell = tableHead || [];
    const tableBodyCell = tableBody || [];
    const tableBodyCells = bodyCells || [];

    // function to return year months days hours minutes
    const reformatDate = (date) => {
      return date.replace('T', ' ').slice(0, 16)
    }


    // function to return table cell value
    const getTableCell = (row, item) => {
      if (item === 'date') {
        return reformatDate(row[item])
      } else {
        return row[item]
      }
    }
  
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadCell.map((cell, i) => (
                <TableCell key={i} sx={customStyle && customStyle.headerTableCell}>{cell}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBodyCell.map((row, j) => (
              <TableRow key={j} sx={customStyle && customStyle.row} onClick={() => clickHandler && clickHandler(row)}>
              {tableBodyCells.map((item, j) => {
                return <TableCell key={j} sx={customStyle && customStyle.tableCell}>{item === 'product_package_size' ? Number(getTableCell(row, item)) : getTableCell(row, item)}</TableCell>;
              })}
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  export default TableComponent;

 /*
Usage:

1. Define your data:
   const sampleData = [
     { name: 'John', number: 123 },
     { name: 'Alex', number: 456 },
   ]

2. Call the TableComponent in your Component:
   <TableComponent
     tableHead={['Name', 'Number']}
     tableBody={sampleData}
     bodyCells={['name', 'number']}
   />

3. Explanation of Props:
   - `tableHead`: An array representing the header cells of the table. Example: ['Name', 'Number'].
   - `tableBody`: An array of objects, where each object represents a row in the table. Example: sampleData.
   - `bodyCells`: An array of strings representing the keys of the objects to display in each row. Example: ['name', 'number'].

Resulting Table Content:
   Name  Number
   John  123
   Alex  456
*/
