import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./contexts/auth-context";
import { AppContextProvider } from "./contexts/app-context";
import { AxiosInstanceProvider } from "./contexts/axios-context";
import { ThemeProvider } from '@mui/material';
import { theme } from './components/theme/theme';
import { urls } from '../src/components/config/variables'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AxiosInstanceProvider config={{ baseURL: urls.BASE_URL }}>
        <AuthContextProvider>
            <AppContextProvider>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ThemeProvider>
            </AppContextProvider>
        </AuthContextProvider>
    </AxiosInstanceProvider>
);
